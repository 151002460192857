export default [
  {
    title: 'Panel',
    icon: 'HomeIcon',
    tag: '2',
    route: 'dashboard',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
  },
  {
    title: 'Kullanıcı',
    icon: 'UserIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'user-list',
      },
    ],
  },
  {
    title: 'Müşteri',
    icon: 'UsersIcon',
    resource: 'm_customer',
    children: [
      {
        title: 'Firmalar',
        route: 'customers-list',

      },
    ],
  },
  {
    title: 'Ürün',
    resource: 'm_product',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ürünler',
        route: 'products-list',
      },
    ],
  },
  {
    title: 'Sipariş',
    icon: 'ShoppingCartIcon',
    resource: 'm_order',
    children: [
      {
        title: 'Sipariş Oluştur',
        route: 'orders-add',
        resource: 'order_edit',
      },
      {
        title: 'Siparişler',
        route: 'orders-list',
        resource: 'order_show',
      },
    ],
  },
  {
    title: 'Konsinye',
    icon: 'WatchIcon',
    resource: 'm_consignment',
    children: [
      {
        title: 'Konsinye Oluştur',
        route: 'consignments-add',
        resource: 'consignment_edit',

      },
      {
        title: 'Konsinyeler',
        route: 'consignments-list',
        resource: 'consignment_show',
      },
    ],
  },
  {
    title: 'Teklif',
    icon: 'ArchiveIcon',
    resource: 'm_proposal',
    children: [
      {
        title: 'Teklif Oluştur',
        route: 'proposals-add',
        resource: 'proposal_edit',
      },
      {
        title: 'Teklifler',
        route: 'proposals-list',
        resource: 'proposal_show',
      },
      {
        title: 'Teklif Ayarları',
        route: 'proposals-settings',
        resource: 'proposal_edit',
      },
    ],
  },
  {
    title: 'Email',
    icon: 'MailIcon',
    resource: 'm_email',
    children: [
      {
        title: 'Toplu Mail Gönder',
        route: 'email-send',
        resource: 'email_send',
      },
      {
        title: 'Gönderim Logları',
        route: 'email-logs',
        resource: 'email_send',
      },
      {
        title: 'E-Mail Kategorileri',
        route: 'email-categories',
        resource: 'email_category_edit',
      },
      {
        title: 'Kara Liste',
        route: 'email-blacklist',
        resource: 'email_send',
      },
    ],
  },
  {
    title: 'Eğitim',
    icon: 'BookOpenIcon',
    resource: 'm_training',
    children: [
      {
        title: 'Eğitimleri Düzenle',
        route: 'training-list',
        resource: 'training_edit',
      },
      {
        title: 'Eğitimlerim',
        route: 'my-trainings',
        resource: 'my_training',
      },
    ],
  },
  {
    title: 'Teknik Servis',
    icon: 'CpuIcon',
    resource: 'technical_service_edit',
    children: [
      {
        title: 'Servis Kayıtları',
        route: 'technical-service',
        resource: 'technical_service_edit',
      },

    ],
  },
  {
    title: 'Raporlar',
    icon: 'TrendingUpIcon',
    resource: 'm_report',
    children: [
      {
        title: 'Satış Raporları',
        route: 'report-sales',
        resource: 'report_sales',
      },
      {
        title: 'Temsilci Hedef Raporu',
        route: 'report-targets',
        resource: 'report_sales',
      },
      {
        title: 'Eğitim Raporları',
        route: 'report-trainings',
        resource: 'report_training',
      },
      {
        title: 'Alacak Raporları',
        route: 'report-debt',
        resource: 'report_debt',
      },
    ],
  },
  {
    title: 'Bilgilendirme İçeriği',
    icon: 'AlignCenterIcon',
    resource: 'm_content',
    children: [
      {
        title: 'İçerik Ekle',
        route: 'content-add',
        resource: 'blog_content_edit',
      },
      {
        title: 'İçerik Listesi',
        route: 'content-list',
        resource: 'blog_content_edit',
      },
    ],
  },
  {
    title: 'Belgeler',
    icon: 'HardDriveIcon',
    route: 'documents',
  },
  {
    title: 'Faaliyet',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'Faaliyetlerim',
        route: 'activity',
      },
      {
        title: 'Faaliyet Tipi',
        route: 'activity-types',
        resource: 'activity_edit',
      },
    ],
  },
  {
    title: 'Görev Tanımla',
    icon: 'CheckCircleIcon',
    resource: 'apps-todo',
    route: 'apps-todo',
  },
  {
    title: 'Bildirim Gönder',
    icon: 'BellIcon',
    resource: 'm_notification',
    route: 'notifications-add',
  },

  {
    title: 'Ayarlar & Tanımlar',
    icon: 'SettingsIcon',
    resource: 'm_config',
    children: [
      {
        title: 'Roller',
        route: 'settings-roles',
      },
    ],
  },

]
